export const config = {
    RPC_URL: 'https://rpc-cosmoshub.ecostake.com',
    REST_URL: 'https://rest-cosmoshub.ecostake.com',
    EXPLORER_URL: 'https://www.mintscan.io/cosmos',
    STAKING_URL: 'https://cosmos.omniflix.co/stake',
    NETWORK_NAME: 'Cosmos',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'cosmoshub-4',
    CHAIN_NAME: 'Cosmos Network',
    COIN_DENOM: 'ATOM',
    COIN_MINIMAL_DENOM: 'uatom',
    COIN_DECIMALS: 6,
    PREFIX: 'cosmos',
    COIN_TYPE: 118,
    COSMOSTAION: 'cosmos',
    COINGECKO_ID: 'cosmos',
    GAS_PRICE_STEP_LOW: 0.01,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
